<template>
  <div class="dataAnalysi">
    <van-nav-bar
      title="区域排行"
      left-text=""
      left-arrow
      :fixed="false"
      :border="false"
      :safe-area-inset-top="true"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        筛选<van-icon :class="show ? 'xia' : ''" name="play" />
      </template>
    </van-nav-bar>
    <div class="list">
      <div class="dataItem" :class="[item.rank == 1 ? 'one' : '', item.rank == 2 ? 'two' : '',item.rank == 3 ? 'three' : '']" v-for="(item,idx) in rankList" :key="'rank'+idx">
        <div class="dataLeft">
          <span>{{item.rank}}</span> {{item.name}}
        </div>
        <div class="dataRight">
          传播值：{{item.score}}
        </div>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false" :lock-scroll="false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <h1 class="title" @click="areaShow = !areaShow">地区筛选<van-icon class="arrbtn" :class="areaShow ? 'xia' : ''" name="play" /></h1>
          <div class="areaList" :class="areaShow ? 'h100' : 'h0'">
            <div class="areaItem" @click="areaClick(item)" :class="item.active == true ? 'on' :'7987'" v-for="(item,idx) in areaList" :key="'area'+idx">{{item.name}}</div>
          </div>
          <h1 class="title" @click="TownShow = !TownShow">街道/乡镇筛选<van-icon class="arrbtn" :class="TownShow ? 'xia' : ''" name="play" /></h1>
          <div class="TownshipList" style="height:200px;" :class="TownShow ? 'h100' : 'h0'">
            <div class="TownshipItem" @click="twonClick(item)" :class="item.active == true ? 'on' :'7987'" v-for="(item,idx) in twonList" :key="'twon'+idx">{{item.name}}</div>
            <div v-show="loadTwon" style="text-align:center;">
              <van-loading size="24px">加载中...</van-loading>
            </div>
            <van-divider v-show="!loadTwon && twonList.length == 0">{{areaActive.length > 0 ? '该地区暂无数据，请选择其它地区' : '暂无数据，请选择地区'}}</van-divider>
          </div>
          <h1 class="title" @click="taskShow = !taskShow">任务筛选<van-icon class="arrbtn" :class="taskShow ? 'xia' : ''" name="play" /></h1>
          <div :class="taskShow ? 'h100' : 'h0'">
            <van-search
              v-model="params.key"
              show-action
              label=""
              placeholder="请输入搜索关键词"
            >
              <template #action>
                <div class="btn" @click="onClickButton">搜索</div>
              </template>
            </van-search>
            <div class="taskList" style="height:5rem;overflow:auto;width:100%;">
              <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
              >
                <!-- <div class="taskItem on">
                  <span>任务名称1</span>2022-02-07
                </div> -->
                <div class="taskItem" @click="taskClick(item)" :class="item.active == true ? 'on' :'-----'" v-for="item in list" :key="item">
                  <span>{{item.title}}</span>{{item.created_at}}
                </div>
              </van-list>
            </div>
          </div>
          <div class="submitHold">
            <van-button @click="clearAll" plain round type="default">清空</van-button>
            <van-button :loading="submitLoading" @click="sureHandel" class="sure" round type="primary">确定</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from './footer.vue'
import Cookies from 'js-cookie'

export default {
  name: 'dataAnalysi',
  setup() {
    const onClickLeft = function () {
      history.back()
    }
    return {
      onClickLeft,
    };
  },
  data () {
    return {
      loadNum: 0,
      loadShow: false,
      areaShow: false, // 区县是否隐藏
      TownShow: false, // 街道/乡镇是否隐藏
      taskShow: false, // 任务是否隐藏
      show: false,
      submitLoading: false, // 按钮加载状态
      list: [],
      rankActive: [],
      loading: false,
      finished: false,
      areaList: [],
      areaActive: [],
      twonList: [],
      twonActive: [],
      loadTwon: false,
      value: '',
      params: {
        key: "",
        page: 1,
        size: 10
      },
      query:{
        area_ids: [],
        duty_ids: []
      },
      rankList: []
	  }
  },
  components: {
    indexFooter
  },
  created () {
    this.loadNum++
    this.loadShow = true
    this.getArea()
    this.getRankCount()
	},
  watch: {
    'show':function (val, oldval){
      if(val){
        $(document.body).css({'overflow':'hidden'})
        this.areaShow = true
        this.TownShow = false
        this.taskShow = false
      } else {
        $(document.body).css({'overflow':'auto'})
      }
    },
    'areaActive':{
      deep: true,//深度监听
      handler:function(val,oldval){
        // 选择区县，调用街道接口
        // console.log(val)
        if (val.length==0) {
          this.twonList = []
          this.twonActive = []
          return
        }
        this.twonList = []
        this.areaTwo(val)
      }
    }
  },
  methods: {
    getArea() {
      let that = this
      let ps = {
        token: Cookies.get('spToken')
      }
      that.$api.index.areaOne(ps).then(function (response) {
        if (response.code === 200) {
          that.loadNum--
          if (that.loadNum==0){
            that.loadShow = false
          }
          console.log(response.result)
          that.areaList = response.result
        }
      })
    },
    areaTwo(arr) {
      let that = this
      this.loadTwon = true
      let ps = {
        token: Cookies.get('spToken'),
        ids: arr
      }
      that.$api.index.areaTwo(ps).then(function (response) {
        if (response.code === 200) {
          that.loadTwon = false
          // console.log(response.result)
          that.twonList = response.result
        }
      })
    },
    // 筛选条件 清楚所有
    clearAll () {
      // 选中的地区数组为 空数组，把地区的数据的 选中标识 都改为false
      this.areaActive = []
      this.areaList.forEach(function(item){
        item.active = false
      })
      // 任务清空数组，标记状态都改为 false 不选中
      this.rankActive = []
      this.list.forEach(function(item){
        item.active = false
      })
    },
    // 筛选条件确定
    sureHandel () {
      this.submitLoading = true
      // 调用接口查询数据
      this.getRankCount()
    },
    // 地区list，item点击事件
    areaClick(item) {
      let that = this
      // 更改数据，添加active属性，来标记显示
      if (!item.active) {
        item.active = true
      } else {
        item.active = !item.active
      }
      // active属性为true，添加到选中数组，为false从数组删除
      if (item.active) {
        that.areaActive.push(item.id)
      } else {
        let index = that.areaActive.indexOf(item.id)
        if(index>-1){
          that.areaActive.splice(index,1)
        }
      }
      // console.log(this.areaActive)
    },
    // 乡镇，街道 item 点击
    twonClick(item){
      let that = this
      // 更改数据，添加active属性，来标记显示
      if (!item.active) {
        item.active = true
      } else {
        item.active = !item.active
      }
      // active属性为true，添加到选中数组，为false从数组删除
      if (item.active) {
        that.twonActive.push(item.id)
      } else {
        let index = that.twonActive.indexOf(item.id)
        if(index>-1){
          that.twonActive.splice(index,1)
        }
      }
      console.log(this.twonActive)
    },
    // 任务list item点击事件
    taskClick(item) {
      let that = this
      // 更改数据，添加active属性，来标记显示
      if (!item.active) {
        item.active = true
      } else {
        item.active = !item.active
      }
      // active属性为true，添加到选中数组，为false从数组删除
      if (item.active) {
        that.rankActive.push(item.id)
      } else {
        let index = that.rankActive.indexOf(item.id)
        if(index>-1){
          that.rankActive.splice(index,1)
        }
      }
      // console.log(that.rankActive)
    },
    onLoad() {
      let that = this
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      that.params.token = Cookies.get("spToken");
      that.$api.index.dutyList(that.params).then(function(response) {
        if (response.code === 200) {
          console.log(response);
          that.list = that.list.concat(response.result);
          that.params.page++;
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          // console.log(response.result.length)
          if (response.result.length < 1) {
            that.finished = true;
          }
        }
      });
    },
    // 页头右侧 筛选按钮点击
    onClickRight() {
      // 显示筛选条件，刷新任务列表
      this.show = !this.show
      if (this.show && this.list.length>0) {
        this.clearAll()
        this.list = []
        this.loading = true;
        this.finished = false;
        this.params.page = 1
        this.onLoad()
      }
    },
    // 任务搜索 按关键字
    onClickButton(){
      console.log(9999)
      this.list = []
      this.loading = true;
      this.finished = false;
      this.params.page = 1
      this.onLoad()
    },
    getRankCount() {
      this.loadNum++
      this.loadShow = true
      let that = this
      that.query.area_ids = that.areaActive
      that.query.duty_ids = that.rankActive
      that.query.sub_area_ids = that.twonActive
      that.query.token = Cookies.get("spToken");
      that.$api.index.rankCount(that.query).then(function(response) {
        that.submitLoading = false
        if (response.code === 200) {
          that.loadNum--
          if (that.loadNum==0){
            that.loadShow = false
          }
          that.show = false
          that.rankList = response.result
        }
      })
    }
  }
}
</script>
<style>
.dataAnalysi .van-search{
  padding: 0;
  border-radius: 50px;
  overflow: hidden;
  margin: 10px 0;
  background: #f7f8fa;
}
.dataAnalysi .van-search__action{
  width: 1.33rem;
  background: #0B47AC;
  border-radius: 50px;
  font-size: 0.35rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}
.dataAnalysi .h100{
  height: auto;
  overflow: auto;
}
.dataAnalysi .h0{
  height: 0!important;
  overflow: hidden;
  padding: 0!important;
  margin-top: 0.27rem;
}
</style>
